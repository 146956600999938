<template>
  <div>
    <CalculatorSettingsHead
            :countFilterParams="countFilterParams"
            :showFilter="showFilter"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">
<!--      <CalculatorSettingsFilter-->
<!--              :showFilter="showFilter"-->
<!--              :filterGetParams="filterGetParams"-->
<!--              @close="closeFilter"-->
<!--              @changeFilter="data => $emit('changeFilter', data)"-->
<!--              @resetFilter="$emit('resetFilter')"-->
<!--      />-->

      <CalculatorSettingsTable
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              @updateTable="$emit('updateTable')"
              @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import CalculatorSettingsHead from "./CalculatorSettingsHead/CalculatorSettingsHead";
  // import CalculatorSettingsFilter from "./CalculatorSettingsFilter/CalculatorSettingsFilter";
  import CalculatorSettingsTable from "./CalculatorSettingsTable/CalculatorSettingsTable";

  export default {
    name: "CalculatorSettingsTableAdmin",

    components: {
      CalculatorSettingsHead,
      // CalculatorSettingsFilter,
      CalculatorSettingsTable,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>

</style>
