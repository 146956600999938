<template>
  <div class="fragment">
    <CalculatorSettingsTableAdmin
        v-if="isAdmin"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @reload="reload"
    />


  </div>

</template>

<script>
  import CalculatorSettingsTableAdmin from "./CalculatorSettingsAdmin/CalculatorSettingsAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "CalculatorSettingsTable",
    components: {
      CalculatorSettingsTableAdmin,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getCalculatorSettingsForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterName: '',
        filterStatus: '',

        filterShipped: [],

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          name: this.$route.query.name,
          status: this.$route.query.status,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextCalculatorSettingsPage', true)
        this.$store.dispatch('fetchCalculatorSettings', url).then(() => {
          this.$store.commit('setNextCalculatorSettingsPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter()
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        // this.generateFilterQueryParams(
        //   myQuery,
        //   {
            // filterId: 'ConsolidationResponsiblePersonId',
            // filterName: 'ConsolidationResponsiblePersonName',
          // },
        // )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }

  }
</script>

<style scoped>

</style>
