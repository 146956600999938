import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function CalculatorSettings() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    label: '',
    dayInterval: '',
    dataByCountries: [],
  }


  this.validation = {
    label: false,
    dayInterval: false,
  }

  this.validationTranslate = {
    label: '',
    dayInterval: '',
  }

  this.validationTxt = {
    label: false,
    dayInterval: false,
  }

  /**
   * Getters
   */
  this.getLabel = () => {
    return this.data.label
  }
  this.getDayInterval = () => {
    return this.data.dayInterval
  }
  this.getDataByCountries = () => {
    return this.data.dataByCountries
  }


  /**
   * Setters
   */
  this.setLabel = (val) => {
    this.data.label = val
  }
  this.setDayInterval = (val) => {
    this.data.dayInterval = val
  }
  this.setDataByCountries = (val) => {
    this.data.dataByCountries = val
  }

}

/**
 * Global Setters
 */

CalculatorSettings.prototype.setItem = function(val) {
  this.setLabel(val.label)
  this.setDayInterval(val.day_interval)
  val.country_settings.map(item => {
    this.data.dataByCountries.push({
      country: item.country_code,
      days: item.day_interval,
    })
  })

}


/**
 * Functions
 */

CalculatorSettings.prototype.addDataCountry = function() {
  this.data.dataByCountries.push({
    country: null,
    days: '',
  })
  return this.data.dataByCountries.length - 1
}

CalculatorSettings.prototype.removeDataCountry = function(index) {
  this.data.dataByCountries.splice(index, 1)
  return this.data.dataByCountries.length - 1
}



/**
 * Prepare Data
 */

CalculatorSettings.prototype.prepareSave = function() {
  let dataCountries = []

  this.data.dataByCountries.map(item => {
    dataCountries.push({
      country_code: item.country,
      day_interval: item.days,
    })
  })

  return {
    label: this.getLabel(),
    // day_interval: this.getDayInterval(),
    country_settings: dataCountries
  }
}


/**
 * Validations
 */

CalculatorSettings.prototype.firstValidation = function() {
  let validationItems = {
    label: this.getLabel(),
    // dayInterval: this.getDayInterval(),
  }

  let validationOptions = {
    label: {type: ['empty']},
    // dayInterval: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}
