<template>
  <div class="fragment">
    <template
            v-if="$store.getters.getCalculatorSettings.length > 0 && $store.getters.getCalculatorSettingsLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_id',
            'common_date',
            'common_dayInterval',
            'consolidationUsers_Name',
            'common_manage',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('common_id.localization_value.value') }}</th>
            <th>{{ $t('common_date.localization_value.value') }}</th>
            <th>{{ $t('common_dayInterval.localization_value.value') }}</th>
            <th>{{ $t('consolidationUsers_Name.localization_value.value') }}</th>
            <th width="100%" class="hide-1400"></th>
            <th>{{ $t('common_manage.localization_value.value') }}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getCalculatorSettings" :key="index">
            <td>
              #{{item.id}}
            </td>

            <td>
              <ValueHelper
                  :value="item.created_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td style="text-align: right;">
              <ValueHelper
                  :value="item"
                  :deep="'day_interval'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'label'"
              />
              <span style="color: red;" class="ml-2" v-if="item.deleted">({{ $t('common_deleted.localization_value.value') }})</span>
            </td>
            <td class="hide-1400"></td>
            <td class="manage-row">
              <div class="table-right table-manage-list table-manage-list--small">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item" v-if="!item.deleted">
                    <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            @click.native="changeCalculatorSettingsPopup(true, item.id)"
                    />
                  </template>
                  <template slot="item" v-if="item.deleted">
                    <LinkButton
                            :value="$t('common_restore.localization_value.value')"
                            :type="'dog'"
                            @click.native="restoreItem(item.id)"
                    />
                  </template>
                  <template slot="item" v-if="!item.deleted">
                    <LinkButton
                            :value="$t('common_delete.localization_value.value')"
                            :type="'delete'"
                            @click.native="deleteItem(item.id)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getCalculatorSettings"
                 :key="index"
            >
              <CalculatorSettingsTableMobile
                      :item="item"
                      @changeCalculatorSettingsPopup="changeCalculatorSettingsPopup(true, item.id)"
                      @deleteItem="deleteItem(item.id)"
                      @restoreItem="restoreItem(item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getCalculatorSettingsCommonList.next_page_url !== null && $store.getters.getCalculatorSettings.length > 0"
                    @click.native="$emit('showMore')"
                    :count="$store.getters.getCalculatorSettingsCommonList.total - $store.getters.getCalculatorSettingsForPage * $store.getters.getCalculatorSettingsCommonList.current_page < $store.getters.getCalculatorSettingsForPage ?
                    $store.getters.getCalculatorSettingsCommonList.total - $store.getters.getCalculatorSettingsForPage * $store.getters.getCalculatorSettingsCommonList.current_page :
                    $store.getters.getCalculatorSettingsForPage"
            />
          </div>
        </div>

      </div>
    </template>

    <template
            v-if="$store.getters.getCalculatorSettingsLoading === false && $store.getters.getCalculatorSettings.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <CalculatorSettingsPopup
            v-if="isModalCalculatorSettingsPopup"
            :itemId="itemId"
            :type="'update'"
            @reload="$emit('reload')"
            @close="changeCalculatorSettingsPopup(false)"
    />
  </div>
</template>

<script>
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import CalculatorSettingsPopup from "../../../../popups/CalculatorSettingsPopup/CalculatorSettingsPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import CalculatorSettingsTableMobile from "./CalculatorSettingsTableMobile/CalculatorSettingsTableMobile";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "CalculatorSettingsTable",
    components: {
      ValueHelper,
      CalculatorSettingsTableMobile,
      NoResult,
      ManagerButton,
      LinkButton,
      ShowMore,
      CalculatorSettingsPopup,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        itemId: -1,
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalCalculatorSettingsPopup: false,

      }
    },

    methods: {
      changeCalculatorSettingsPopup(val, id = false) {
        if (id) {
          this.itemId = id
        }
        this.isModalCalculatorSettingsPopup = val

        if (!val) {
          this.itemId = -1
        }
      },

      deleteItem(id) {
        let text = {
          title: 'common_deleteConfirm',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          this.$store.dispatch('deleteCalculatorSettings', id).then(response => {
            if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.openNotify('success', 'common_notificationRecordDeleted')
              this.$emit('reload')
            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      restoreItem(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {
          this.$store.dispatch('restoreCalculatorSettings', id).then(response => {
            if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('reload')
            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }
</script>

<style scoped lang="scss">
  .comment-row {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 115px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  @media (max-width: 1400px) {
    .comment-row {
      width: 100px;
    }
  }

  .table-manage-list .manager-btn {
    width: 200px;
  }

  @media (max-width: 1400px) {
    .table-manage-list .manager-btn {
      width: 100px;
    }

    .user-field-admin {
      flex-wrap: wrap;
      display: flex;
    }

    .hide-1400 {
      display: none;
    }
  }

  /*.site-table thead th:first-child,*/
  /*.site-table tbody td:first-child {*/
  /*padding-right: 0;*/
  /*}*/

  /*.site-table thead th:nth-child(2),*/
  /*.site-table tbody td:nth-child(2) {*/
  /*padding-left: 0;*/
  /*}*/

  /*.site-table thead th:last-child,*/
  /*.site-table tbody td:last-child {*/
  /*padding-right: 0;*/
  /*}*/

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  .size-24 {
    width: 24px;
    height: 24px;
    margin-top: -2px;
  }


</style>
