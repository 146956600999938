<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_CreateItem',
            'common_ChangeItem',
          ])"></div>
        <template v-if="type === 'create'">
          {{$t('common_CreateItem.localization_value.value')}}
        </template>
        <template v-else>
          {{$t('common_ChangeItem.localization_value.value')}}
        </template>
      </div>

    </template>
    <template slot="body">
      <div class="profiles-expenses__content">

        <div class="profiles-expenses__row custom-row">
          <div class="profiles-expenses__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['consolidationUsers_Name'])"></div>
            <DefaultInput
                    :label="$t('consolidationUsers_Name.localization_value.value')"
                    v-bind:class="{'ui-no-valid': CU.validation.label}"
                    :error="CU.validation.label"
                    :errorTxt="$t(`${CU.validationTranslate.label}.localization_value.value`)"
                    :type="'text'"
                    v-model="CU.data.label"
            />
          </div>
          <div class="profiles-expenses__col custom-col mb-0">
            <div class="order-create__title-horizontal"
                 v-for="(item, index) in CU.data.dataByCountries"
                 :key="index + '1'"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--              <div class="admin-edit" @click="editTranslate([-->
<!--						'product_Product',-->
<!--						'common_DeleteProduct',-->
<!--					])"></div>-->
              <TitleHorizontal
                  :value="$t('common_dayInterval.localization_value.value') + ' #' + parseInt(index + 1)"
                  :rightBtn="true"
                  :rightBtnType="'delete'"
                  :rightBtnText="$t('common_delete.localization_value.value')"
                  @rightBtnClick="CU.removeDataCountry(index)"
              />


              <div class="custom-row">
                <div class="custom-col custom-col--50 custom-col--md-100"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_UPCCode',
                ])"></span>
                  <DefaultSelect
                      :options="getOptionsCountry(item)"
                      :label="$t('common_country.localization_value.value')"
                      :selected="getSelectedCountry(item)"
                      :optionsLabel="'label'"
                      @change="(val) => changeCountry(item, val)"
                  />
<!--                      v-bind:class="{'ui-no-valid': ex.express.delivery.validation.country}"-->
<!--                      :errorTxt="serverError ? ex.express.delivery.validationTxt.country : $t(`${ex.express.delivery.validationTranslate.country}.localization_value.value`)"-->
<!--                      :error="ex.express.delivery.validation.country"-->
                </div>
                <div class="custom-col custom-col--50 custom-col--md-100"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate(['common_dayInterval',])"></span>
                  <DefaultInput
                      :label="$t('common_dayInterval.localization_value.value')"
                      :type="'text'"
                      v-model="item.days"
                  />
<!--                      v-bind:class="{'ui-no-valid': CU.validation.dayInterval}"-->
<!--                      :error="CU.validation.dayInterval"-->
<!--                      :errorTxt="$t(`${CU.validationTranslate.dayInterval}.localization_value.value`)"-->
                </div>
              </div>
            </div>
          </div>

          <div class="profiles-expenses__col custom-col">
            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>
                <MainButton class="wfc secondary"
                            :value="$t('common_AddNew.localization_value.value')"
                            :ico="'ico'"
                            @click.native="CU.addDataCountry()"
                >
                  <template slot="ico">
                    <PlusAccentIconSVG class="btn-icon-plus"/>
                  </template>
                </MainButton>
              </div>
            </div>
          </div>

<!--          <div class="profiles-expenses__col custom-col"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['consolidationUsers_Name'])"></div>-->
<!--            <DefaultInput-->
<!--                    :label="$t('common_dayInterval.localization_value.value')"-->
<!--                    v-bind:class="{'ui-no-valid': CU.validation.dayInterval}"-->
<!--                    :error="CU.validation.dayInterval"-->
<!--                    :errorTxt="$t(`${CU.validationTranslate.dayInterval}.localization_value.value`)"-->
<!--                    :type="'text'"-->
<!--                    v-model="CU.data.dayInterval"-->
<!--            />-->
<!--          </div>-->
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-between align-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="type === 'create'"
                :value="$t('common_create.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
                v-bind:class="{'disabled-btn' : $store.getters.getCalculatorSettingsBtn}"
        />
        <MainButton
                v-else
                :value="$t('common_update.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
                v-bind:class="{'disabled-btn' : $store.getters.getCalculatorSettingsBtn}"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import {CalculatorSettings} from "../../models/CalculatorSettings";
  import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import PlusAccentIconSVG from "../../../../../../public/img/UI-group/plus-accent-icon.svg?inline";


  export default {
    name: "CalculatorSettingsPopup",
    components: {
      DefaultSelect,
      TitleHorizontal,
      DefaultInput,
      Modal,
      MainButton,
      PlusAccentIconSVG,
    },


    props: {
      itemId: Number,
      type: String,
    },

    data() {
      return {
        CU: new CalculatorSettings(),

        optionsCountry: [],

      }
    },

    mounted() {
      if(this.itemId){
        this.$store.dispatch('getCalculatorSettings', this.itemId).then(() => {
          this.CU.setItem(this.$store.getters.getCalculatorSettingsItem)
          this.optionsCountry = this.$store.getters.getCalculatorSettingsItem?.possible_country_list

          // this.CU.data.dataByCountries.map(selectedCountry => {
          //   let foundIndex = this._.findIndex(this.optionsCountry, {country_code: selectedCountry.country})
          //   if(foundIndex > -1) {
          //     this.optionsCountry.splice(foundIndex, 1)
          //   }
          // })

          if(this.CU.getDataByCountries().length === 0) {
            this.CU.addDataCountry()
          }

        }).catch(error => this.createErrorLog(error))
      } else {
        this.CU.addDataCountry()
      }
    },

    methods: {

      getOptionsCountry(item) {
        let data = []

        if(this._.find(this.optionsCountry, {'country_code': item.country})) {
          data.push(this._.find(this.optionsCountry, {'country_code': item.country}))
        }

        this.optionsCountry.map(country => {
          let found = this._.find(this.CU.data.dataByCountries, {country: country.country_code})
          if(!found) {
            data.push(country)
          }
        })

        return data
      },

      getSelectedCountry(item) {
        return this._.find(this.optionsCountry, {'country_code': item.country})
      },

      changeCountry(item, val) {
        item.country = val?.country_code
      },

      save() {
        if(!this.CU.firstValidation()) return

        let saveType = 'createCalculatorSettings'

        let data = this.CU.prepareSave()

        if (this.type === 'update') {
          saveType = 'updateCalculatorSettings'
          data = {
            data: data,
            id: this.itemId
          }
        }

        this.$store.dispatch(saveType, data).then((response) => {

          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if (this.type === 'update') {
                this.openNotify('success', 'common_notificationRecordChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
              }
              this.$emit('close')
              this.$emit('reload')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }
    }

  }

</script>

<style lang="scss" scoped>
  @media(max-width: 550px){
    .transaction-info__col{
      width: 100%;
    }

    .scoped-btns > button{
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
      margin-bottom: 10px;
    }
  }
</style>
